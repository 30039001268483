import { useLoadScript } from '@react-google-maps/api'
import { MatxTheme } from 'app/components'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../fake-db'
import { req } from './auth/request'
import { Store } from './redux/Store'
import { AllPages } from './routes/routes'

const App = () => {
    const [shouldShow, setShouldShow] = useState(true)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCzZ7ZOAB2J6QaH2N1G0nAYds4fDfFjF5Y', // Add your API key
        libraries: ['drawing'],
    })
    const all_pages = useRoutes(AllPages())

    useEffect(() => {
        console.log('window.location.pathname', window.location.pathname)
        if (window.location.pathname == '/admin') return
        if (window.location.href.includes('/signin')) return
        const jwt = localStorage.getItem('jwt')
        if (!jwt) window.location.href = '/session/signin'
        req('/user/check-jwt', 'POST', { jwt }, 'static').then((res) => {
            if (!res?.verified) window.location.href = '/session/signin'
            localStorage.setItem('user', JSON.stringify(res?.verified))

            console.log('--------user in app.jsx', res?.verified)

            const user = JSON.parse(localStorage.getItem('user'))
            if (user?.roleName == 'Administrator') return
            const routeReplacements = {
                '/admin': '',
                '/admin/editProduct': 'products',
                '/admin/dashboard/default': 'products',
                '/admin/orders': 'orders',
                '/admin/viewOrder': 'internet_orders',
                '/admin/internet-orders': 'internet_orders',
                '/admin/photos': 'photos',
                '/admin/towns': 'towns',
                '/admin/stadiums': 'stadiums',
                '/admin/characters': 'characters',
                '/admin/parts': 'parts',
                '/admin/colors': 'colors',
                '/admin/pages': 'pages',
                '/admin/productTypes': 'productTypes',
                '/admin/vendors': 'vendors',
                '/admin/employees': 'employees',
                '/admin/managers': 'employees',
                '/admin/suppliers': 'suppliers',
                '/admin/overrides': 'overrides',
                '/admin/categories': 'categories',
            }
            const accessName = routeReplacements[window.location.pathname]
            console.log(
                'acccibilities',
                window.location.pathname,
                accessName,
                user
            )
            if (!user?.access?.[accessName]) setShouldShow(false)
        })
    })

    return shouldShow ? (
        <Provider store={Store}>
            <SettingsProvider>
                <MatxTheme>
                    <ToastContainer />
                    <AuthProvider>{all_pages}</AuthProvider>
                </MatxTheme>
            </SettingsProvider>
        </Provider>
    ) : (
        <p>
            <span>Inaccessible</span> <span style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => {
                localStorage.clear()
                window.location.href = '/session/signin'
            }}>Logout</span>
        </p>
    )
}

export default App
